"use client";
import React from "react";
import Image from "next/image";
import { CardGridProps } from "@/components/CardGrid/types";
import { ClientOnly } from "@/components/Cohesion/ClientOnly";
import LinkWithChildren from "@/components/Link/LinkWithChildren/LinkWithChildren";
import { cn } from "@/lib/utils";
import useHomepageExploreTopCourses from "@/monarch/rules/homepageExploreTopCourses/useHomepageExploreTopCourses";

const baseGridClass = "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4";
const baseGridItemClass =
  "bg-shadow p-4 rounded-xl border border-solid h-[100px] md:h-[180px] lg:h-[220px] md:max-w-[222] hover:shadow-lg hover:shadow-gray-400 no-underline hover:no-underline flex items-center justify-center";
const baseGridItemContentClass =
  "w-full flex items-center text-center md:flex-col md:justify-center md:items-center gap-4";

export default function Primary({
  cards,
  title,
  html_id = "",
  location = "",
  className,
  gridClassName = "",
  gridItemClassName = "",
  gridItemContentClassName = "",
}: CardGridProps) {
  const { isLoading, variant } = useHomepageExploreTopCourses();
  const experimentActivated = location === "popular-topics" && !isLoading;
  const hidePopularTopics = experimentActivated && variant === "variant2";
  if (hidePopularTopics) {
    return null;
  }
  return (
    <div className={cn("py-20", className)}>
      {title && (
        <div>
          <h2 className="text-2xl text-primary mb-6">{title}</h2>
        </div>
      )}
      <div className={cn(baseGridClass, gridClassName)}>
        {cards.map((card, index) => (
          <ClientOnly key={card.altText}>
            <LinkWithChildren
              item={card}
              index={index}
              key={card.altText}
              html_id={html_id}
              location={location}
              class_name={cn(baseGridItemClass, gridItemClassName)}
            >
              <div
                className={cn(
                  baseGridItemContentClass,
                  gridItemContentClassName,
                )}
              >
                {card.img ? (
                  <Image
                    src={card.img}
                    alt=""
                    width={150}
                    height={150}
                    className="m-0 mr-5 md:m-auto h-12 w-20 lg:h-20 lg:w-44 object-cover lg:object-none"
                  />
                ) : null}
                <span className="text-base text-left md:text-center text-primary md:text-lg font-bold">
                  {card.altText}
                </span>
              </div>
            </LinkWithChildren>
          </ClientOnly>
        ))}
      </div>
    </div>
  );
}
