import { useMonarchRule } from "@/monarch/rules/MonarchRulesProvider";
import {
  HomepageExploreTopCoursesVariant,
  HomepageExploreTopCoursesTestResult,
  UseHomepageExploreTopCoursesTraffic,
} from "./types";

export const CUSTOM_RULE_ID = "homepageExploreTopCourses";

const variantMap = {
  0: "control",
  1: "variant1",
  2: "variant2",
};

/**
 * Hook for the Homepage Explore Top Courses experiment.
 * This experiment can return one of three variants:
 * - control: Popular Topics (Card Grid) only
 * - variant1: Explore Top Courses and Popular Topics (Card Grid)
 * - variant2: Explore Top Courses only
 */
const useHomepageExploreTopCoursesTraffic: UseHomepageExploreTopCoursesTraffic =
  () => {
    const { isLoading, value } = useMonarchRule<{
      data: { variant: 0 | 1 | 2 };
    }>(CUSTOM_RULE_ID, {});

    // If still loading or no value returned, return loading state
    if (isLoading || !value) {
      return {
        isLoading,
      } as HomepageExploreTopCoursesTestResult;
    }

    // Determine the variant based on the returned data
    const variant = variantMap[
      value.data.variant
    ] as HomepageExploreTopCoursesVariant;

    console.log("VARIANT:", variant);

    return {
      isLoading,
      variant,
    };
  };

export default useHomepageExploreTopCoursesTraffic;
